import Amplify from 'aws-amplify';

import { awsConfig } from '../gallery/constants';
Amplify.configure(awsConfig);

import AsyncStorage from "@react-native-async-storage/async-storage";
import { DrawerContentComponentProps, createDrawerNavigator } from '@react-navigation/drawer';
import { CommonActions, NavigationContainer, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { CorePluginClass } from "halia";
import { HOCRegister, HaliaComponentPlugin, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import * as React from 'react';
import { Button, FlatList, Image, PanResponder, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, ViewProps } from "react-native";
import { Icon } from "react-native-elements";
import {
  MD3LightTheme as DefaultTheme,
  Provider as PaperProvider,
  Switch,
} from 'react-native-paper';
import { View } from 'react-native-web';
import { AuthContext, AuthProvider } from "../../packages/kelp-bar/auth";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { GalleryButton } from "../gallery/components/common";
import { useAppType } from "../gallery/sizes-helper";
import { PluginStore } from "../halia-studio/halia-studio-plugin/plugin-list";
import { Authenticate } from "./authenticate";
import { EntityContext } from "./entity-plugin";

import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';

const uuid = require('uuid/v4');

//  CONSIDER:  Should this be INJECTED by the "Entity" Plugin??
//  CONSIDER:  Could / should we use a NounService instead of doing this manually??
const Settings = () => {

  const { hessia2Plugin } = React.useContext(Hessia2Context);
  const [storedConfig, setStoredConfig] = React.useState<HessiaConfig | undefined>(undefined);
  const [_config, _setConfig] = React.useState<HessiaConfig | undefined>(undefined);
  const { syncState } = React.useContext(EntityContext);

  const loadConfig = async () => {
    const currentConfig = await hessia2Plugin?.getConfig();
    _setConfig(currentConfig);
    setStoredConfig(currentConfig);
  }

  React.useEffect(() => {
    loadConfig();
  }, []);

  if (!_config) {
    return <Text>Loading Config</Text>
  }

  const saveSettings = async () => {
    await hessia2Plugin?.setConfig(_config);
    setStoredConfig(_config);
  };

  const awsEnabled = _config?.storage == 'aws';
  const dirty = (JSON.stringify(_config) != JSON.stringify(storedConfig));

  const toggleAws = () => {
    _setConfig({ ..._config, storage: _config.storage === 'aws' ? 'local' : 'aws' });
  }

  const updateAwsConfig = (field: string, value: string) => {
    _setConfig({ ..._config, awsConfig: { ..._config.awsConfig, [field]: value } });
  }

  /**
   * NOTE:  Generated by GPT!
   * TODO:  Make a library of AWS / Amplify tools.
   * @returns 
   */

  const UserProfile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch the user info when the component mounts
    useEffect(() => {
      const fetchUserInfo = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const { username, attributes } = user;
          // Set user info
          setUserInfo({ username, ...attributes });
        } catch (error) {
          console.error('Error fetching user info:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchUserInfo();
    }, []);

    if (loading) {
      return (
        <View style={styles.container}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        {userInfo ? (
          <View>
            <Text style={styles.text}>Welcome, {userInfo.username}!</Text>
            <Text style={styles.text}>Email: {userInfo.email}</Text>
            {/* Display other attributes if needed */}
          </View>
        ) : (
          <Text style={styles.text}>No user info available.</Text>
        )}
      </View>
    );
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
    },
    text: {
      fontSize: 18,
      marginBottom: 10,
    },
  });

  /**
   * TODO:  Display PROFILE Picture.
   * TODO:  Display USERNAME
   * TODO:  Update EMAIL
   */
  const { logout } = React.useContext(AuthContext);
  return (
    <View style={{ backgroundColor: 'white' }}>



      <Text>Use AWS?</Text>
      <Switch value={awsEnabled} onValueChange={toggleAws} />

      <UserProfile />
      <Text>AWS Config</Text>
      <TextInput placeholder="Bucket" value={_config.awsConfig?.bucket} onChangeText={(value) => updateAwsConfig("bucket", value)} />
      <TextInput placeholder="Bucket Key" value={_config.awsConfig?.bucketKey} onChangeText={(value) => updateAwsConfig("bucketKey", value)} />
      <TextInput placeholder="Key" value={_config.awsConfig?.key} onChangeText={(value) => updateAwsConfig("key", value)} />
      <TextInput placeholder="Region" value={_config.awsConfig?.region} onChangeText={(value) => updateAwsConfig("region", value)} />
      <TextInput placeholder="Secret" value={_config.awsConfig?.secret} onChangeText={(value) => updateAwsConfig("secret", value)} />

      {
        dirty && <Button title="Save" onPress={saveSettings} />
      }

      <Text>Saved?</Text>
      <Text>{syncState}</Text>

      <Button title="Logout" onPress={() => { logout() }} />
    </View>
  );
}

//
//  Systems
//

export interface System {
  pinned?: boolean;  //  TODO:  This should NOT be a part of the system?
  priority?: number; //  TODO:  This should NOT be part of the system?
  id: string;
  name: string;
  description: string;
  emoji: string;
  color: string;
  component: any;
  focus?: boolean;
  icon: {
    name: string;
    type: string;
  },
  primaryColor: string;
  backgroundColor: string;

  //  CONSIDER:  We may have other info associated with it, but perhaps it's easier to make these mappings in the system itself.
  //  CONSIDER:  We may want to require entity providers and other standards.
}

const SystemsList = ({ systems }: { systems: System[] }) => {
  const navigation = useNavigation();
  return (
    <>
      <Text style={{ fontSize: 16, fontFamily: 'Poppins-SemiBold', marginVertical: 10 }}>Systems</Text>
      <FlatList data={systems} renderItem={({ item: system }) => <GalleryButton emoji={system.emoji} style={{ backgroundColor: system.color }} title={system.name} onPress={() => navigateToScreen(system.name as never, navigation)} />} />
    </>


  );
}


//
//  Interface
//

const DrawerNav = createDrawerNavigator();

function hexToRgbString(hex) {
  let r = 0, g = 0, b = 0;

  // Handling 3 digits hex color
  if (hex.length == 4) {
    r = parseInt("0x" + hex[1] + hex[1]);
    g = parseInt("0x" + hex[2] + hex[2]);
    b = parseInt("0x" + hex[3] + hex[3]);

    // Handling 6 digits hex color
  } else if (hex.length == 7) {
    r = parseInt("0x" + hex[1] + hex[2]);
    g = parseInt("0x" + hex[3] + hex[4]);
    b = parseInt("0x" + hex[5] + hex[6]);
  }

  return `${r},${g},${b}`;
}

export interface CustomSVG {
  color: string;
  size: number;
}

// export const SystemTitle = ({ name, primaryColor, icon, backgroundColor }: System2) => {
//   const navigation = useNavigation();
//   return (
//     <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', borderRadius: 5, marginBottom: 5, paddingVertical: 5, justifyContent: "flex-start", height: 50, borderColor: `#eeeeee`, borderWidth: 0 }}>
//       <SystemIcon icon={icon} backgroundColor={backgroundColor} primaryColor={primaryColor} />
//       <Text style={{ fontFamily: 'Inter-SemiBold', fontSize: 16, color: '#555555', marginLeft: 10 }}>{capitalizeAllWords(name)}</Text>
//     </View>
//   );
// }

//  REFERENCE:  GPT-4
const ResizableColumn = (props: ViewProps) => {
  const [width, setWidth] = React.useState(200); // Initial width
  const [isHovered, setIsHovered] = React.useState(false); // Is mouse over the handle?

  const panResponder = React.useRef(PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (evt, gestureState) => {
      setWidth(gestureState.moveX);
    },
    onPanResponderEnd: () => {

    }
  })).current;

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <View style={{ ...styles.column, width }}>
      <View style={{ flex: 1 }}>
        {props.children}
      </View>
      <View
        style={[styles.handle, { width: isHovered ? 3 : 1 }]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...panResponder.panHandlers}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  column: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  handle: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 1,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: '#eeeeee', // Make the handle slightly visible
  },
});

export default ResizableColumn;


// const LeftMenu = ({ systems, selectedSystem }: { systems: System2[], selectedSystem }) => {


//   const navigation = useNavigation();

//   return (
//     <ResizableColumn style={{ backgroundColor: 'white', width: 200, borderRightWidth: 1, borderRightColor: '#eeeeee' }}>

//       {/* Systems Widget */}
//       <View style={{ flex: 1, padding: 20 }}>

//         <Text style={{ fontFamily: 'Inter-Bold', fontSize: 11, color: '#888888', marginBottom: 16, letterSpacing: 2 }}>SYSTEMS</Text>

//         {/* rgba(${ hexToRgbString(system.primaryColor) },0.2) */}
//         {
//           systems.map(system => (

//             <SystemTitle {...system} />

//           ))
//         }
//       </View>
//       <View style={{ padding: 20, borderTopWidth: 1, borderTopColor: '#eeeeee' }}>
//         <Text style={{ fontFamily: 'Inter-SemiBold', fontSize: 12, color: '#333333', marginBottom: 16 }}>Settings</Text>
//         <SystemTitle name="Plugins" primaryColor='#19abff' backgroundColor='#def0ff' icon={{ name: 'plug', type: 'font-awesome' }} onPress={() => navigation.navigate("Extensions" as never)} />
//         <SystemTitle name="Clear" primaryColor='#19abff' backgroundColor='#def0ff' icon={{ name: 'close', type: 'font-awesome' }} onPress={async () => { alert("Attempting Clear");  await AsyncStorage.clear(); alert("Cleared Async"); }} />
//       </View>

//     </ResizableColumn>
//   );


// }


const navigateToScreen = (screenName, navigation) => {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: screenName }],
    }),
  );
};


export interface HessiaDrawerContentFactoryOptions {
  logoUrl: string;

}

// export const HessiaDrawerContentFactory = (options: HessiaDrawerContentFactoryOptions) => {

// };

const HessiaDrawerContent = (props: DrawerContentComponentProps) => {

  const { isDesktop } = useSizes();
  const { systems } = React.useContext(Hessia2Context);

  if (!systems.length) { return <Text>Loading</Text> }

  const filteredRoutes = ["Extensions"];

  const allowedRoutes = props.state.routes.filter(
    route => !filteredRoutes.includes(route.name)
  );

  const { navigation } = props;

  const HessiaMenuButton = ({ name, focused, icon, onPress, onLongPress }: { name: string, focused: boolean, icon: { name: string, type: string }, onPress?: () => void, onLongPress?: () => void }) => {
    return (
      <TouchableOpacity onLongPress={() => onLongPress ? onLongPress() : null} style={{ marginHorizontal: 0, marginBottom: 0, height: 54, paddingHorizontal: 15 }} onPress={() => onPress ? onPress() : navigation.navigate(name)}>
        <View style={{ backgroundColor: focused ? '#f5fcfa' : 'transparent', borderRadius: focused ? 22 : 0, height: 44, width: '100%', paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderColor: 'transparent', borderWidth: focused ? 2 : 0 }}>
          <Icon size={22} type={icon.type} name={icon.name} color={focused ? "#1ee8a5" : "#666666"} />
          <Text style={{ fontFamily: 'Outfit-SemiBold', color: focused ? "#1ee8a5" : "#666666", fontSize: 16, marginLeft: 20 }}>{name}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  // const exploreFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Explore");
  // const createFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Create");
  const extensionsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Extensions");
  const integrationsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Integrations");
  const settingsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Settings");
  console.log(JSON.stringify(systems));

  const PinnedRoutes = () => {

    const hasPinned = systems.find(system => system.pinned === true);

    if (!hasPinned) { return null; }

    const getSelectedSystem = () => {
      for (let i = 0; i < allowedRoutes.length; i++) {
        const focused = i === props.state.index;
        const systemName = allowedRoutes[i].name;
        const system = systems.find(system => system.name === systemName);
        if (focused) { return system; }
      }
    }

    const selectedSystem = getSelectedSystem();

    return (
      <>
        {
          allowedRoutes.filter(({ name }, index) => {
            const system = systems.find(system => system.name === name);
            if (!system || !system.pinned) { return undefined; }
            return system;
          })
            .sort((a, b) => {
              const aSystem = systems.find(system => system.name === a.name);
              const bSystem = systems.find(system => system.name === b.name);
              return (aSystem?.priority || 0) - (bSystem?.priority || 0);
            })
            .map((route, index) => {
              const system = systems.find(system => system.name === route.name);
              if (!system) { return null; }
              const selected = selectedSystem?.id === system?.id;
              return <HessiaMenuButton icon={system.icon} focused={selected} name={system.name} onLongPress={() => alert(JSON.stringify(system))} />
            })
        }

        {/* Divider */}
        <View style={{ marginVertical: 20, height: 1.5, width: 160, backgroundColor: "#eeeeee", borderRadius: 1, marginHorizontal: 'auto' }} />
      </>
    );
  }



  return (
    <>

      {/* Header  */}
      <View style={{ height: 65, witch: '100%', alignItems: 'center', backgroundColor: 'white', justifyContent: 'flex-start', flexDirection: 'row' }}>
        <View style={{ height: '100%', width: '100%', backgroundColor: isDesktop ? sideBarColor : 'transparent', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20 }}>
          <Image style={{ height: 35, width: 35, borderRadius: 15, marginLeft: 0 }} source={require("../../assets/stickers/Hessia Logo 4.png")} />
          <View style={{ width: 12 }} />
          <Text style={{ fontFamily: 'Outfit-SemiBold', fontWeight: "400", fontSize: 20, color: '#666666' }}>Hessia</Text>
        </View>
      </View>
      <ScrollView style={{ flex: 1, paddingVertical: 5 }} {...props}>

        {/* Pinned Systems */}
        {/* TODO:  The interface here should NOT be systems!  We're installing a BUTTON that's it! */}
        {/* <HessiaMenuButton icon={{ type: "octicon", name: "telescope" }} focused={exploreFocused} name="Search" onPress={() => navigation.navigate("Explore")} />
        <HessiaMenuButton icon={{ type: "feather", name: "loader" }} focused={createFocused} name="Create" onPress={() => navigation.navigate("Create")} /> */}
        <PinnedRoutes />

        {/* Normal Systems */}
        {/* TODO:  This should be a Widget Area */}
        {allowedRoutes.map(({ name }, index) => {
          const focused = index === props.state.index;
          const system = systems.find(system => system.name === name);
          if (!system || system.pinned) { return <></> }
          return <HessiaMenuButton icon={system.icon} focused={focused} name={name} onLongPress={() => alert(JSON.stringify(system))} />
        })}
      </ScrollView>

      {/* Divider */}
      <View style={{ marginVertical: 20, height: 1.5, width: 160, backgroundColor: "#eeeeee", borderRadius: 1, marginHorizontal: 'auto' }} />

      {/* Extensions Button */}
      <HessiaMenuButton icon={{ type: "feather", name: "zap" }} focused={extensionsFocused} name="Extensions" onPress={() => navigation.navigate("Extensions")} />
      <HessiaMenuButton icon={{ type: "feather", name: "link" }} focused={integrationsFocused} name="Integrations" onPress={() => navigation.navigate("Integrations")} />
      <HessiaMenuButton icon={{ type: "feather", name: "settings" }} focused={settingsFocused} name="Settings" onPress={() => navigation.navigate("Settings")} />

      {/* Bottom Space */}
      <View style={{ height: 15 }} />
    </>

  );
}

const sideBarColor = 'white';


const HessiaPluginStore = () => {
  return <PluginStore options={{ basePluginId: Hessia2Plugin.details.id, immediateOnly: true }} />
}

const HessiaWorkspace = () => {

  const { systems, selectedSystem, selectSystem } = React.useContext(Hessia2Context);

  const navigation = useNavigation();
  const systemRef = React.useRef(systems);
  systemRef.current = systems;

  //  Sets the Initial Route 
  //  NOTE:  ChatGPT helped with this
  //  TODO:  Make this dynamic.
  //  CONCERN:  There may be a race condition between systems changing and mounting as a screen?
  React.useEffect(() => {
    for (const system of systems) {
      if (system.focus) {
        selectSystem(system);
        navigation.reset({
          index: 0,
          routes: [{ name: system.name as never }],
        });
        navigateToScreen(system.name as never, navigation);
      }
    }
  }, [systems]);

  const { isDesktop } = useSizes();

  console.log("RENDERING HESSIA WORKSPACE");

  return (
    <DrawerNav.Navigator drawerContent={HessiaDrawerContent} key={isDesktop ? 'desktop' : 'mobile'} initialRouteName='Dashboard' screenOptions={{ drawerStyle: { width: 200, borderRightWidth: 0, borderRightColor: '#eeeeee', backgroundColor: sideBarColor }, drawerType: isDesktop ? 'permanent' : 'front', headerShown: false }}>
      {
        systems.map(system => <DrawerNav.Screen key={system.name} name={system.name} component={system.component} />)
      }
      <DrawerNav.Screen key="extensions" name="Extensions" component={HessiaPluginStore} />
      <DrawerNav.Screen key="integrations" name="Integrations" component={HessiaPluginStore} />
      <DrawerNav.Screen key="settings" name="Settings" component={Settings} />
    </DrawerNav.Navigator>
  );
}
const StackNav = createStackNavigator();


const HessiaApp = () => {

  // CONSIDER:  SHOULD be able to INJECT login stuff with a pluign instead of having it here explicitly.  Enable it as a composable feature.

  const { authState } = React.useContext(AuthContext);
  console.log(JSON.stringify(authState));

  if (authState.user) {
    return <HessiaWorkspace />;
  } else {
    return (
      <StackNav.Navigator>
        <StackNav.Screen name="Authenticate" component={({ navigation, route }) => <Authenticate mode={route?.params?.mode} />} />
      </StackNav.Navigator>
    );
  }

}

const HessiaProvider = ({ hessia2Plugin }: { hessia2Plugin: Hessia2Plugin }) => {

  //  TODO:  Re-Enable Navigation to Main Screen
  //  TODO:  Want to generalize this to more than just React... should be able to express systems in audio, text, etc.  THIS then is the App version I suppose.
  //  NOTE:  The settings for the app are ALWAYS stored locally.  Then, we can use the target set there to pull in the app.
  //  TODO:  Build a SYSTEM space where we can log things like app starts, events, etc!  THIS way we have a record of what we've debugged and the app journey!  

  const [systems, setSystems] = React.useState<System[]>([]);
  const systemRef = React.useRef(systems);
  systemRef.current = systems;

  const installSystem = (system: System, navigate?: boolean) => {

    //  TODO:  Register an entity for EACH system.

    setSystems(prevSystems => {
      if (!prevSystems.map(prevSystem => prevSystem.name).includes(system.name)) {
        const updatedSystems = [...prevSystems, system];
        return updatedSystems;
      }
      return prevSystems;
    });
  };

  //  CONSIDER:  Make a QUICK GPT integrator that takes a SCHEMA for an object and converts it into a component and then we can QUICKLY render a component!!!  Make a BUNCH of simple AI connected components!  STILL want to have a website for all these things mm!!!  This is part of Oranda and CAN have its own pricing model mm!



  React.useEffect(() => {
    console.log("Mounting Hessia");
  }, []);

  const getSystemById = (id: string): System | undefined => {
    return systems.find(system => system.id === id);
  }
  const [selectedSystem, selectSystem] = React.useState<System | undefined>(undefined);
  const paperTheme = {
    ...DefaultTheme
  };

  const loadConfig = async () => {
    const configString = await AsyncStorage.getItem("config");
    if (configString) {
      console.log("Loaded Hessia Config");
      const config: HessiaConfig = JSON.parse(configString);
      setConfig(config);
    } else {
      console.log("No Hessia Config.  Creating and using Default.");
      await AsyncStorage.setItem("config", JSON.stringify(DefaultHessiaConfig));
      setConfig(DefaultHessiaConfig);
    }

  };

  //  Load Config
  React.useEffect(() => {
    loadConfig();
  }, []);

  const [config, setConfig] = React.useState<HessiaConfig>(DefaultHessiaConfig);

  const type = useAppType();
  return (
    <PaperProvider theme={paperTheme}>
      <Hessia2Context.Provider value={{ selectedSystem, selectSystem, installSystem, getSystemById, systems, hessia2Plugin }}>
        <NavigationContainer independent={true}>
          <HOCRegister id="hessia2">
            <AuthProvider>
              <View style={{ flexDirection: 'column', backgroundColor: 'white', flex: 1 }}>
                {/* Top Spacing for Mobile / PWA */}
                <View style={{ flex: 1, flexDirection: 'row', paddingTop: type != 'web' ? 54 : 0 }}>
                  <HessiaApp />
                </View>
              </View>
            </AuthProvider>
          </HOCRegister>
        </NavigationContainer>
      </Hessia2Context.Provider>
    </PaperProvider>
  )
}

//
//  Hessia2 Plugin
//

export const DefaultHessiaConfig: HessiaConfig = { storage: 'local', awsConfig };

//  TODO:  Support multiple SPACES and SETTINGS!  MAYBE this is where we select a space and configure its settings?? hmm...  Space COULD also be a system hmm..  FOR NOW, I'm just going to configure this with AWS and that's it.

export interface AWSConfig {
  key: string;
  secret: string;
  region: string;
  bucket: string;
  bucketKey: string;
}

export interface HessiaConfig {
  storage: 'aws' | 'local';
  awsConfig: AWSConfig;
}

export interface Hessia2Context {

  //  Systems
  installSystem: (system: System, navigate?: boolean) => void;
  getSystemById: (id: string) => System | undefined;
  systems: System[];
  selectSystem: React.Dispatch<React.SetStateAction<System | undefined>>;
  selectedSystem?: System;
  hessia2Plugin?: Hessia2Plugin;
}

export const Hessia2Context = React.createContext<Hessia2Context>({ selectSystem: (value) => null, selectedSystem: undefined, installSystem: (system, navigate) => null, getSystemById: (id: string) => undefined, systems: [] });



//  CONSIDER:  Why do we need to register this?  Can we do it dynamically based on id passed to the HOC register?  
//  TODO:  We use an HOCRegister so we can mount additional components.  HOWEVER, this breaks isolation as only one can be registered.  We will want to fix that!

/**
 * The Hessia Plugin!
 * 
 * This is the Plugin which mounts Hessia into the Halia App and makes it possible to register SYSTEMS as the FUNDAMENTAL thing.
 * These are simlar to "Apps" in some ways, but where a system doesn't NEED a front-end.  They are a space injected with RESOURCES and the INSTALLER can do whatever it wants with it.
 * 
 */
export class Hessia2Plugin extends CorePluginClass {

  public static details = {
    name: "Hessia",
    description: "Hessia 2 Core Plugin",
    dependencies: [HaliaComponentPlugin.details.id],
    id: "hessia2"
  }

  public getConfig = async (): Promise<HessiaConfig | undefined> => {
    const configString = await AsyncStorage.getItem("config");
    if (!configString) {
      await this.setConfig(DefaultHessiaConfig);
    }
    const config: HessiaConfig = configString ? JSON.parse(configString) : DefaultHessiaConfig;
    return config;
  }

  public registerContext = (Context: React.Context<any>, initialState: any) => {
    this.registerHOC(({ children }) => (
      <Context.Provider value={initialState}>
        {children}
      </Context.Provider>
    ))
  };

  public setConfig = async (config: HessiaConfig): Promise<void> => {
    await AsyncStorage.setItem("config", JSON.stringify(config));
  }

  public registerHOC = (wrapper: any) => {
    registerHOC("hessia2", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("hessia2", wrapper);
  }

  public install = async (program: any, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    registerHOCRegister("hessia2");
    haliaComponentPlugin.registerChildComponent(() => <HessiaProvider hessia2Plugin={this} />);
    return this;
  }
}



