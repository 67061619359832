
import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import { ScrollView, TextInput, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { NounServiceInstanceInternal } from '../../../packages/noun-service/noun-service';
import { GalleryButton } from '../../gallery/components/common';
import { Edge, PropertyPlugin } from '../../hessia2/property-plugin';
import { Entity2Plugin, EntityTable } from '../../hessia2/entity-plugin';
import { Entity } from '../../hessia2/entity-service';
import { Hessia2Plugin } from '../../hessia2/hessia2-plugin';
import { AaroSystem } from '../aaro-core';
import { HabitContext, HabitEntity, HabitPlugin } from './habits.extension';
import { Icon } from 'react-native-elements';
import { TextSubParagraph } from '../../../packages/kelp-bar/text';
import { SystemHeader } from '../../../packages/kelp-bar/system-header';
import { GroupCard } from '../../../packages/kelp-bar/group-card';

//  TODO-CRITICAL:  When we import something like context, we SHOULD do it from the Plugin so we don't have to get it from the global space???  THIS way we CAN have instances of it!
//  CONSIDER:  Add a "Filter" by (or perhaps a Stack By) Feature
//  CONSIDER:  Add a "Filter" option to Filter Top Level Only
//  TODO:  SHOULD make it easy to select "Habit"...  CAN do this with the Entity system.  FOR NOW, we'll just do it directly I guess UGH...  CAN show a list of entities and DISPLAY them in various ways... like their type.
//  CONSIDER:  I've writte this logic for displaying child / parent things SO many times!!!  I'd like to generalize it and use the graph so I can re-use that logic EVEN if it's contextualized.
//  CONSIDER:  A function is JUST a bunch of symbols that are influencing.  The idea is to be able to auto-bind based on the refrences?  Should be "smart" in that it can process an input based on the prior layer like a net.  A net works on SPECIFIC input.  THen it directs behavior for that input mm!
//  TODO-CRITICAL!!!!! - We DEPEND on React Context from OTHER Plugins!  INSTEAD of even having the "Plugin", just make it a react context which gets injected based on it's dependencies!  THis way hmmm... we put it where it needs to be??? hmm...Why not just put them all in the root? Hmm... or use soe other system for context hmm!

const hierarchyPluginId = "hierarchy";

export const HierarchyPluginContext = React.createContext<HierarchyPlugin | undefined>(undefined);

export class HierarchyPlugin extends CorePluginClass {

  public static details = {
    name: 'Hierarchy',
    description: 'Introduces Hierarchy',
    dependencies: [HabitPlugin.details.id, Entity2Plugin.details.id, PropertyPlugin.details.id, Hessia2Plugin.details.id],
    id: hierarchyPluginId
  }

  public childOfPredicateId = "childOfPredicate";

  public getChildren = async (entityId: string) => {
    const children = await this.graph2.getIncomingEntities(entityId, this.childOfPredicateId);
    return children;
  }

  public getParents = async (entityId: string) => {
    const parents = await this.graph2.getOutgoingEntities(entityId, this.childOfPredicateId);
    return parents;
  }

  public setEntityParent = async (entityId: string, parentId: string) => {
    const edge: Edge = { sub: entityId, pred: this.childOfPredicateId, obj: parentId };
    await this.graph2.createEdge(edge, true, []);
  }

  public unsetEntityParent = async (entityId: string, parentId: string) => {
    const incomingParentEdges = this.graph2.getIncomingEdges(parentId, this.childOfPredicateId);
    const childOfEdge = (await incomingParentEdges).find(edge => edge.payload.value?.sub === entityId);
    if (!childOfEdge) { alert("Missing 'childOf' edge!  Could not delete."); return; }
    await this.entity2.entityService.entityNounService.delete(childOfEdge?.id);
  }

  public hasChild = async (entityId: string, childId: string) => {
    const children = await this.getChildren(entityId);
    if (children.find(child => child.payload.id === childId)) {
      return true;
    }
    return false;
  }

  public setEntityChild = async (entityId: string, childId: string) => {
    const hasExisting = await this.hasChild(entityId, childId);
    if (hasExisting) { alert("Child Exists Already"); return; }
    const edge: Edge = { sub: childId, pred: this.childOfPredicateId, obj: entityId };
    await this.graph2.createEdge(edge, true, []);
    alert("Added Child");
  }

  public graph2!: PropertyPlugin;
  public habit!: HabitPlugin;
  public entity2!: Entity2Plugin;

  public install = async (program: Program, { habit, entity2, graph2, hessia2 }: { hessia2: Hessia2Plugin, habit: HabitPlugin, entity2: Entity2Plugin, graph2: PropertyPlugin }) => {

    this.graph2 = graph2;
    this.habit = habit;
    this.entity2 = entity2;

    //  Build the "Child Of" Predicate (if needed)
    const existingInstanceOf = await entity2.entityService.getEntityById(this.childOfPredicateId);
    if (!existingInstanceOf) {
      await graph2.createPredicate({ exported: true, id: this.childOfPredicateId, name: "Child Of", description: "A Predicate used to mark a 'Child' to a 'Parent'.", owners: [AaroSystem] });
    }

    entity2.registerEntityExtension({
      name: "Tree",
      description: "Adds Hierarchy",
      id: "hierarchy-entity-extension",
      DetailComponent: ({ entity }: { entity?: NounServiceInstanceInternal<Entity> }) => {

        if (!entity) { return <Text>No Entity</Text>; }

        const hierarchy = React.useContext(HierarchyPluginContext);
        if (!hierarchy) {
          alert("No Hierarchy Plugin!");
          return <Text>Missing Hierarchy Plugin</Text>;
        }

        const [selectedId, setSelectedId] = React.useState<string | undefined>(undefined);
        const [parents, setParents] = React.useState<NounServiceInstanceInternal<Entity<any>>[]>([]);
        const [children, setChildren] = React.useState<NounServiceInstanceInternal<Entity<any>>[]>([]);

        const loadData = async () => {
          const subHabits = await hierarchy?.getChildren(entity?.payload.id);
          const parents = await hierarchy?.getParents(entity?.payload.id);
          if (subHabits) { setChildren(subHabits); }
          if (parents) { setParents(parents); }
        }

        React.useEffect(() => {
          loadData();
        }, []);

        const updateParentHabit = async () => {
          if (!selectedId) { alert("Please select a parent ID"); return; }
          const res = await hierarchy?.setEntityParent(entity?.payload.id, selectedId);
          alert(JSON.stringify(res));
          await loadData();
        }

        return <View style={{ backgroundColor: 'white', flex: 1 }}>

          <SystemHeader breadcrumbs={false} system={{ name: "Tree", icon: { type: "material", name: "account-tree" } }} />

          <ScrollView style={{ padding: 30 }}>

            <Text style={{ fontFamily: "Outfit-SemiBold", fontSize: 20, color: "#333333", flex: 1 }}>Parents</Text>
            <EntityTable entities={parents} />

            <Text style={{ fontFamily: "Outfit-SemiBold", fontSize: 20, color: "#333333", flex: 1 }}>Children</Text>
            <EntityTable entities={children} />

            <GroupCard>
              <Text style={{ fontFamily: "Outfit-SemiBold", fontSize: 20, color: "#333333", flex: 1 }}>Select a Parent</Text>
              <TextInput value={selectedId} onChangeText={(_parentId) => setSelectedId(_parentId)} />
              <GalleryButton title='Set Parent' onPress={updateParentHabit} />
            </GroupCard>

          </ScrollView>
        </View>
      },
      systemId: "hierarchy",
      icon: { name: "account-tree", type: "material" }
    });

    //  TODO:  IF we want to couple directly with Habits, then use a COUPLING Plugin to extend this and break it up.  It's like a different Markov Blanket.
    habit.registerHOC(({ children }) => {
      const { installHabitFilter } = React.useContext(HabitContext);
      React.useEffect(() => {
        installHabitFilter({
          name: "Sub-Habit Filter",
          component: ({ updateFilteredHabits }: { updateFilteredHabits: (allowedHabits: NounServiceInstanceInternal<HabitEntity>[]) => void }) => {

            const [enabled, setEnabled] = React.useState(false);
            const { habits } = React.useContext(HabitContext);

            //  Filters Non-Root Habits
            const filter = async () => {

              if (!enabled) {
                updateFilteredHabits(habits);
                return;
              }

              const filteredHabits: NounServiceInstanceInternal<HabitEntity>[] = [];
              for (const habit of habits) {

                //  I want to get the PARENTS that go THROUGH a predicate.
                const parents = await graph2.getOutgoingEntities(habit.payload.id);
                if (!parents.length) {
                  filteredHabits.push(habit);
                }
              }
              updateFilteredHabits(filteredHabits);
            }

            React.useEffect(() => {
              filter();
            }, [enabled, habits]);

            React.useEffect(() => {
              filter();
            }, []);


            //  COLLAPSE - Another common feature... KIND of different from group by.  Maybe register collapse groups? hmm. SHOULD be able to make new ones arbitrarily too.
            //  Parents Only (Group)
            //  Children Only (Ungroup)
            //  All ()
            return (
              <TouchableOpacity onPress={() => setEnabled(!enabled)} style={{ borderRadius: 10, backgroundColor: "#fafafa", flexDirection: "column", padding: 10, maxWidth: 70, height: 70, alignItems: 'center', justifyContent: 'center' }}>
                <Icon color="#aaaaaa" name={enabled ? "object-group" : "object-ungroup"} type="font-awesome" />
                <Text style={{ fontSize: 10, fontFamily: "Outfit-SemiBold", color: "#777777", textAlign: 'center', marginTop: 5 }}>{enabled ? "Grouped Children" : "Ungrouped Children"}</Text>
                {/* <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  
                </View> */}
              </TouchableOpacity>

            );
          }
        });

      }, []);
      return (
        <>
          {children}
        </>
      );
    });

    hessia2.registerHOC(({ children }) => {
      return (
        <HierarchyPluginContext.Provider value={this}>
          {children}
        </HierarchyPluginContext.Provider>
      );
    });


    return this;
  }
}